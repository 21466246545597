<template>
    <b-col cols="6" class="d-flex justify-content-end">
        <b-input-group class="input-group-merge ml-auto">
            <b-form-input
                type="search"
                placeholder="ابحث عن بريد محدد"
                v-model="inboxFilter.search"
                @input="filterInbox(1)"
            />
            <b-input-group-append is-text>
                <unicon width="16" height="16" fill="gray" name="search" />
            </b-input-group-append>
        </b-input-group>
    </b-col>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters(['inboxFilter'])
    },
    methods: {
        ...mapActions(["filterInbox"])
    }
}
</script>